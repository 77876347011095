<template>
  <div class="main-page">
    <welcome-modal></welcome-modal>
    <overlay-screen
      @showschedule="$bvModal.show('schedule')"
      @showfaq="$bvModal.show('faq')"
      @showdivisi="$bvModal.show('divisi')"
    ></overlay-screen>
    <schedule-modal></schedule-modal>
    <faq-modal></faq-modal>
    <divisi-modal></divisi-modal>
    <!-- <audio ref="audio" :src="audiow" preload="auto" id="player" loop></audio> -->
  </div>
</template>

<script>
import WelcomeModal from "../modal/WelcomeModal";
import OverlayScreen from "./OverlayScreen";
import ScheduleModal from "../modal/ScheduleModal";
import FaqModal from "../modal/FaqModal";
import DivisiModal from '../modal/DivisiModal'

export default {
  components: {
    WelcomeModal,
    OverlayScreen,
    ScheduleModal,
    FaqModal,
    DivisiModal
  },
  data() {
    return{
      audiow: require("../../assets/sound/bgm.mp3"),
    }
  },
};
</script>

<style>
.main-page {
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
</style>