<template>
  <b-modal
    header-class="schmodal__header"
    content-class="schmodal__content schmodal__customscroll"
    body-class="schmodal__body"
    hide-footer
    id="schedule"
    scrollable
    centered
    backdrop
  >
    <template #modal-header="{ close }">
      <h5 class="float-left">{{ title }}</h5>
      <img
        src="../../assets/icons/close-circle.svg"
        class="close-schdialog"
        alt="close"
        @click="close()"
      />
    </template>
    <div class="d-block">
      <div class="ml-2 mb-2">
        <button
          class="btn-datesch mr-2"
          :class="{
            'btn-datesch-active': firstDay,
          }"
          @click="changeDay"
        >
          <p class="btn-content-d">27</p>
          <p class="btn-content-m">march</p>
        </button>
        <button
          class="btn-datesch"
          :class="{
            'btn-datesch-active': !firstDay,
          }"
          @click="changeDay"
        >
          <p class="btn-content-d">28</p>
          <p class="btn-content-m">march</p>
        </button>
      </div>

      <div class="sch_title-wrapper">
        <h5 class="ml-3">Main Stage</h5>
      </div>
      <div class="sch-list">
        <div v-if="firstDay">
          <schedule-detail
            v-for="item in scheduleSabtu"
            :key="item.id"
            :startTime="item.startTime"
            :endTime="item.endTime"
            :eventName="item.eventName"
            :personName="item.speaker"
          ></schedule-detail>
        </div>
        <div v-else>
          <schedule-detail
            v-for="item in scheduleMinggu"
            :key="item.id"
            :startTime="item.startTime"
            :endTime="item.endTime"
            :eventName="item.eventName"
            :personName="item.speaker"
          ></schedule-detail>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import ScheduleDetail from "../ui/ScheduleDetail.vue";

export default {
  props: ["idDialog", "title", "startTime", "endTime", "eventName"],
  components: {
    ScheduleDetail,
  },
  data() {
    return {
      firstDay: true,
    };
  },
  computed: {
    scheduleSabtu() {
      return this.$store.getters.scheduleSabtu;
    },
    scheduleMinggu() {
      return this.$store.getters.scheduleMinggu;
    },
  },
  methods: {
    changeDay() {
      this.firstDay = !this.firstDay;
    },
  },
};
</script>

<style>
.close-schdialog {
  position: absolute;
  top: -5%;
  right: -6%;
  cursor: pointer;
}
.schmodal__header {
  padding: 0;
  border-bottom: 0;
}
.schmodal__content {
  border-radius: 15px;
  border: none;
  max-height: 50vmin !important;
  max-width: 360px;
  background-color: #e6e6e6;
}
.schmodal__body {
  padding: 0px !important;
  background-color: transparent;
  overflow-y: hidden;
  /* position: inherit; */
}
.modal-dialog-scrollable .modal-content.schmodal__content {
  overflow: inherit;
}
.sch_title-wrapper {
  background-color: #4d9897;
  letter-spacing: 1px;
  border-radius: 15px 15px 0 0;
  color: white;
  height: 50px;
  display: flex;
  align-items: center;
}
.sch_title-wrapper h5 {
  margin-bottom: 0;
}
.sch-list {
  padding: 10px;
  background-color: white;
  border-radius: 0 0 15px 15px;
  height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
}

/* date */
.btn-datesch {
  text-align: center;
  padding: 15px 7px 5px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid transparent;
  color: #cecece;
  background-color: white;
}
.btn-datesch-active {
  box-shadow: 0px 3px 6px #00000029;
  color: #ffffff;
}
.btn-datesch:focus {
  outline: none;
}
.btn-datesch-active {
  background-color: #67c8c7;
}

.btn-content-d {
  margin: 0;
  font-size: 34px;
  font-weight: 700;
  letter-spacing: 1.8 px;
  line-height: 16.3px;
}
.btn-content-m {
  margin: 0;
  font-size: 14px;
  letter-spacing: 0.96px;
}
/* Scrollbar */
/* width */
.schmodal__customscroll ::-webkit-scrollbar {
  width: 45px;
  background: transparent;
}
/* Track */
.schmodal__customscroll ::-webkit-scrollbar-track {
  margin-top: 15px;
  margin-bottom: 25px;
  background: transparent;
  border-radius: 10px;
}
.schmodal__customscroll ::-webkit-scrollbar-thumb {
  border-right: 21px solid rgba(0, 0, 0, 0);
  border-left: 21px solid rgba(0, 0, 0, 0);
  border-radius: 20px;
  background-clip: padding-box;
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

/* Handle */
.schmodal__customscroll ::-webkit-scrollbar-thumb {
  background-color: #4d9897;
}

/* 16:9 */
@media only screen and (min-aspect-ratio: 9/20) and (orientation: portrait) {
  .schmodal__content {
    max-height: 50vmax !important;
    max-width: 80vmin;
  }
  .test2 {
    top: 13vmax;
    left: 10vmin;
  }
}
/* Portrait Ipad */
@media only screen and (min-width: 768px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .sch-list {
    height: 23vh;
  }
  .close-schdialog {
    top: -3%;
    right: -4%;
  }
}
</style>