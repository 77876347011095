export const galleryMixin = {
  computed: {
    name() {
      return this.gallery.name;
    },
    about() {
      return this.gallery.about;
    },
    video() {
      return this.gallery.media.video
    },
    image1() {
      if (this.gallery.media.photo1 !== "") {
        return "./assets/photos/" + this.gallery.media.photo1 + ".jpg";
      } else {
        return ""
      }
    },
    image2() {
      if (this.gallery.media.photo2 !== "") {
        return "./assets/photos/" + this.gallery.media.photo2 + ".jpg";
      } else {
        return ""
      }
    },
    image3() {
      if (this.gallery.media.photo3 !== "") {
        return "./assets/photos/" + this.gallery.media.photo3 + ".jpg";
      } else {
        return ""
      }
    },
    image4() {
      if (this.gallery.media.photo4 !== "") {
        return "./assets/photos/" + this.gallery.media.photo4 + ".jpg";
      } else {
        return ""
      }
    },
    image1Link() {
      return require("../../public/assets/photos/" + this.gallery.media.photo1 + ".jpg")
    },
    image2Link() {
      return require("../../public/assets/photos/" + this.gallery.media.photo2 + ".jpg")
    },
    image3Link() {
      return require("../../public/assets/photos/" + this.gallery.media.photo3 + ".jpg")
    },
    image4Link() {
      return require("../../public/assets/photos/" + this.gallery.media.photo4 + ".jpg")
    }
  },
}