<template>
  <b-modal
    v-if="show"
    header-class="welcome__header"
    content-class="welcome__content"
    body-class="welcome__body"
    hide-header
    hide-footer
    id="stage"
    scrollable
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
  >
    <div class="title">
      <h1>WELCOME</h1>
      <h2>To Main Stage</h2>
      <hr />
    </div>
    <div class="content px-5 py-2 text-center">
      <p>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quibusdam aut,
        ad nulla qui corrupti nemo dolore perspiciatis reiciendis ex at eveniet
        incidunt, tempora labore. Modi placeat nisi adipisci. Maiores, quo. lore
      </p>
      <b-button
        class="btn-enter"
        @click="hideStage"
        id="welcomeOk"
        >ENTER</b-button
      >
    </div>
  </b-modal>
</template>

<script>
export default {
  data() {
    return{
      show: true
    }
  },
  methods: {
    hideStage() {
      this.$root.$emit('bv::hide::modal', 'stage')
      this.show = false
    }
  },
};
</script>

<style scoped>
.title {
  text-align: center;
  letter-spacing: 0.8px;
  font-weight: 500;
}
.title h1 {
  color: #4d9897;
}
.title h2 {
  color: #67c8c7;
}
hr {
  border-top: 3px solid #67c8c7;
}
.btn-enter {
  background-color: #67c8c7;
  border: none;
  width: 180px;
  font-weight: 500;
  border-radius: 15px;
}
.btn-enter:focus,
.btn-enter:active,
.btn-enter:active:focus {
  background-color: #67c8c7 !important;
  box-shadow: none !important;
  outline: none;
}
@media only screen and (min-aspect-ratio: 9/20) and (orientation: portrait) {
  .content {
    height: 30vh;
  }
}
</style>

<style>
.welcome__content {
  border-radius: 15px;
}
.welcome__body {
  padding: 10px 0;
}
</style>