import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import { store } from './store/index.js';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import App from './App.vue'

Vue.config.productionTip = false

// Set mobile height
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

new Vue({
  render: h => h(App),
  store
}).$mount('#app')
