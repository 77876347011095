<template>
  <div id="app">
    <all-view></all-view>
    
  </div>
</template>

<script>
import AllView from "./components/view/AllView";
import Vue from "vue";

import { Gallery1, Gallery2, Gallery3, Gallery4, Gallery5, Gallery6, Ask1} from './components/hotspot/hotspothelper'



export default {
  components: { AllView },
  data() {
    return{
      audiow: require("./assets/sound/bgm.mp3"),
    }
  },
  mounted() {
    // var toGallery1Comp = Vue.extend(ToGallery);
    // var instanceToGallery1 = new toGallery1Comp();
    // var iconToGallery = this.$root.$el.nextElementSibling.nextElementSibling.nextElementSibling.childNodes[1].firstChild.childNodes[0].firstChild
    // instanceToGallery1.$mount();
    // iconToGallery.replaceWith(instanceToGallery1.$el);

    // var toStage1Comp = Vue.extend(ToStage);
    // var instanceToStage1 = new toStage1Comp();
    // var iconToStage = this.$root.$el.nextElementSibling.nextElementSibling.nextElementSibling.childNodes[1].firstChild.childNodes[1].firstChild
    // instanceToStage1.$mount();
    // iconToStage.replaceWith(instanceToStage1.$el);

    var Gallery1Comp = Vue.extend(Gallery1);
    var instanceGallery1 = new Gallery1Comp();
    var iconGallery1 = this.$root.$el.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.childNodes[3].firstChild.childNodes[1].firstChild
    instanceGallery1.$mount();
    iconGallery1.replaceWith(instanceGallery1.$el);

    var Gallery2Comp = Vue.extend(Gallery2);
    var instanceGallery2 = new Gallery2Comp();
    var iconGallery2 = this.$root.$el.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.childNodes[3].firstChild.childNodes[4].firstChild
    instanceGallery2.$mount();
    iconGallery2.replaceWith(instanceGallery2.$el);

    var Gallery3Comp = Vue.extend(Gallery3);
    var instanceGallery3 = new Gallery3Comp();
    var iconGallery3 = this.$root.$el.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.childNodes[3].firstChild.childNodes[2].firstChild
    instanceGallery3.$mount();
    iconGallery3.replaceWith(instanceGallery3.$el);

    var Gallery4Comp = Vue.extend(Gallery4);
    var instanceGallery4 = new Gallery4Comp();
    var iconGallery4 = this.$root.$el.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.childNodes[3].firstChild.childNodes[6].firstChild
    instanceGallery4.$mount();
    iconGallery4.replaceWith(instanceGallery4.$el);

    var Gallery5Comp = Vue.extend(Gallery5);
    var instanceGallery5 = new Gallery5Comp();
    var iconGallery5 = this.$root.$el.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.childNodes[3].firstChild.childNodes[3].firstChild
    instanceGallery5.$mount();
    iconGallery5.replaceWith(instanceGallery5.$el);

    var Gallery6Comp = Vue.extend(Gallery6);
    var instanceGallery6 = new Gallery6Comp();
    var iconGallery6 = this.$root.$el.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.childNodes[3].firstChild.childNodes[5].firstChild
    instanceGallery6.$mount();
    iconGallery6.replaceWith(instanceGallery6.$el);

    var Ask1Comp = Vue.extend(Ask1);
    var instanceAsk1 = new Ask1Comp();
    var iconAsk1 = this.$root.$el.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.childNodes[1].firstChild.childNodes[2].firstChild
    instanceAsk1.$mount();
    iconAsk1.replaceWith(instanceAsk1.$el);

    var Ask2Comp = Vue.extend(Ask1);
    var instanceAsk2 = new Ask2Comp();
    var iconAsk2 = this.$root.$el.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.nextElementSibling.childNodes[2].firstChild.childNodes[1].firstChild
    instanceAsk2.$mount();
    iconAsk2.replaceWith(instanceAsk2.$el);

  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700&display=swap");

body {
  font-family: "Red Hat Display", sans-serif !important;
  padding: 0;
  margin: 0;
}
#app {
  position: absolute;
}
</style>
