<template>
  <div>
    <img
      v-b-modal.divisi
      src="../../../assets/icons/interact.gif"
      alt="interact"
      class="icon-interact link-hotspot-icon"
    />
    <divisi-modal :name="name" :about="about">
      <template #imgcarousel>
        <!-- Video-->
        <b-carousel-slide v-if="video !== ''">
          <template #img>
            <iframe
              class="video-carousel"
              :src="video"
              allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </template>
        </b-carousel-slide>

        <b-carousel-slide v-if="image1 !== '' ">
          <template #img>
            <b-button class="cap-gall"
              ><a :href="image1Link" target="_blank">View detail</a></b-button
            >
            <img
              class="d-block flatimage"
              width="auto"
              height="398px"
              :src="image1"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>

        <b-carousel-slide v-if="image2 !== '' ">
          <template #img>
            <b-button class="cap-gall"
              ><a :href="image2Link" target="_blank">View detail</a></b-button
            >
            <img
              class="d-block flatimage-landscape"
              width="100%"
              height="auto"
              :src="image2"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>

        <b-carousel-slide v-if="image3 !== '' ">
          <template #img>
            <b-button class="cap-gall"
              ><a :href="image3Link" target="_blank">View detail</a></b-button
            >
            <img
              class="d-block flatimage-landscape"
              width="100%"
              height="auto"
              :src="image3"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>

        <b-carousel-slide v-if="image4 !== '' ">
          <template #img>
            <b-button class="cap-gall"
              ><a :href="image4Link" target="_blank">View detail</a></b-button
            >
            <img
              class="d-block flatimage-landscape"
              width="100%"
              height="auto"
              :src="image4"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>

      </template>
    </divisi-modal>
  </div>
</template>


<script>
import DivisiModal from "../../modal/DivisiModal";
import { store } from "../../../store/index.js";
import { galleryMixin } from "../../../mixins/galleryMixin";

export default {
  components: {
    DivisiModal,
  },
  mixins: [galleryMixin],
  computed: {
    gallery() {
      return store.getters.gallery[2];
    },
  },
};
</script>