<template>
  <b-modal
    :id="idDialog"
    hide-footer
    scrollable
    centered
    backdrop
    header-class="headerdialog-booth"
    body-class="bodydialog-booth"
    content-class="boothdialog__content"
    modal-class="customscroll__booth"
  >
    <template #modal-header="{ close }">
      <img
        id="closegallery"
        src="../../assets/icons/close-circle.svg"
        class="close-boothdialog"
        alt="close"
        @click="close()"
      />
    </template>
    <div class="block">
      <slot></slot>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ["idDialog", "title", "boothcontent"],
};
</script>

<style>
.dialog-title {
  color: #4d9897;
  font-size: 22px;
  letter-spacing: 1.2px;
  font-weight: bold;
}
.headerdialog-booth {
  padding: 0;
  border-bottom: 0;
}
.close-boothdialog {
  overflow: auto;
  margin-left: auto;
  position: absolute;
  top: -3%;
  right: -5%;
  z-index: 10000;
}
.close-boothdialog:hover {
  cursor: pointer;
}
/* .bodydialog-booth {
  padding: 30px 30px;
  overflow-x: hidden;
} */
.modal-dialog-scrollable .modal-content.boothdialog__content {
  overflow: inherit;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  /* width: 50vh; */
  max-height: 80vh;
}

/* Scrollbar */
/* width */
.customscroll__booth ::-webkit-scrollbar {
  width: 13px;
  background: transparent;
}
.customscroll__booth ::-webkit-scrollbar-track {
  margin-top: 5px;
  margin-bottom: 15px;
  background: transparent;
  border-radius: 10px;
}
.customscroll__booth ::-webkit-scrollbar-thumb {
  /* border-right: 2px solid rgba(0, 0, 0, 0); */
  border-left: 11px solid rgba(0, 0, 0, 0);
  border-radius: 1px;
  background-clip: padding-box;
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

/* Handle */
.customscroll__booth ::-webkit-scrollbar-thumb {
  background-color: #4d9897;
}

/* 20:9 */
@media only screen and (min-aspect-ratio: 9/20) and (orientation: portrait) {
  .modal-dialog-scrollable .modal-content.boothdialog__content {
    width: 94vmin;
  }
}

/* Portrait Ipad */
@media only screen and (min-width: 768px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .modal-dialog-scrollable .modal-content.boothdialog__content {
    width: 35vh;
    max-height: 50vh;
  }
}
</style>