<template>
  <div>
    <div class="overlay-menu d-flex justify-content-center">
      <div class="icons-wrapper">
        <img
          @click="showMap"
          src="../../assets/icons/map.svg"
          alt="map"
          class="overlay-icon"
        />
        <p class="menu-title">Map</p>
      </div>

      <div class="icons-wrapper iconsidebar">
        <img
          src="../../assets/icons/soundon.png"
          alt=""
          class="overlay-icon soundOn"
          @click="offTheSound"
        />
        <img
          src="../../assets/icons/soundoff.png"
          alt=""
          class="overlay-icon soundOff"
          @click="onTheSound"
        />
        <p class="menu-title">Sound</p>
      </div>

      <div class="icons-wrapper">
        <img
          src="../../assets/icons/faq.png"
          alt=""
          class="overlay-icon"
          @click="$emit('showfaq')"
        />
        <p class="menu-title">FAQ</p>
      </div>
      <div class="icons-wrapper iconsidebar">
        <img
          src="../../assets/icons/schedule.svg"
          alt=""
          class="overlay-icon"
          @click="$emit('showschedule')"
        />
        <p class="menu-title">Schedule</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      soundOn: true,
    };
  },
  methods: {
    showMap() {
      document.getElementById("marketingmap").style.display = "block";
    },
    onTheSound() {
      var audio = this.$root.$el.nextElementSibling;
      audio.play();
      document.getElementsByClassName("soundOff")[0].style.display = "none";
      document.getElementsByClassName("soundOn")[0].style.display = "block";
    },
    offTheSound() {
      var audio = this.$root.$el.nextElementSibling;
      audio.pause();
      document.getElementsByClassName("soundOn")[0].style.display = "none";
      document.getElementsByClassName("soundOff")[0].style.display = "block";
    },
  },
};
</script>

<style>
.soundOff{
  display: none;
}
.overlay-menu {
  width: auto;
  height: 11vh;
  background-color: #e6e6e6;
  z-index: 10;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.75% 1% 1% 1%;
  border-radius: 20px 20px 0 0;
}
.icons-wrapper {
  border: 1px solid transparent;
  border-radius: 50%;
  text-align: center;
  width: 50px;
  height: 50px;
}
.map-icon {
  width: 50%;
  margin: 8px auto;
}
.iconsidebar {
  margin: 0px 3px;
}
.icons-wrapper:hover {
  /* border: 1px solid yellow; */
}
.overlay-icon {
  width: 67%;
  margin: 3px auto;
}
.overlay-icon:hover {
  cursor: pointer;
}
.overlay-icon:focus {
  outline: none;
  border: none;
}
.menu-title {
  font-size: 12px;
  color: #636363;
  /* letter-spacing: 1px; */
  margin-bottom: 0;
}
@media only screen and (min-aspect-ratio: 9/20) and (orientation: portrait) {
  .overlay-menu {
    height: 10.5vh;
    position: fixed;
  }
  .iconsidebar {
    margin: 0 10px;
  }
}
@media screen and (min-device-width: 1400px) and (max-device-width: 2000px) and (-webkit-min-device-pixel-ratio: 1) {
  .overlay-menu {
    height: 9vh;
    position: fixed;
  }
}
@media only screen and (min-aspect-ratio: 9/16) and (orientation: portrait) {
  .overlay-menu {
    height: 10vh;
    position: fixed;
    padding: 1.25% 1% 2% 1%;
  }
}
/* Portrait Ipad */
@media only screen and (min-width: 768px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .overlay-menu {
    padding: 1.25% 1% 2% 1%;
    height: 8vh;
  }
}
</style>
