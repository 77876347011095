const state = {
  gallery: [
    {
      id: 1,
      name: "Brand Development",
      logo: "",
      links: {
        website: "",
        whatsapp: "",
        instagram: "",
        brosur: "",
      },
      media: {
        video: "https://www.youtube.com/embed/aItdVTurtGA",
        video2: "https://www.youtube.com/embed/T88lN8AWvnU",
        video3: "https://www.youtube.com/embed/DwBlS5MMXAU",
        photo1: "1-1",
        photo2: "1-2",
        photo3: "1-3",
        photo4: "1-4",
        photo5: "1-5",
        photo6: "1-6",
        photo7: "1-7",
        photo8: "1-8",
      },
      about:
        "A brand is something greater than being just the “face” of a company or product. As a brand, we tell stories. Our purpose is not only to build financial value but also to inspire people. We are responsible for the formulation and implementation of brand strategies. A brand cannot function without a comprehensive understanding of brand’s customer needs and trends that build strong brand value and optimize profits.",
    },
    {
      id: 2,
      name: "Media and Strategic Partnership",
      logo: "",
      links: {
        website: "",
        whatsapp: "",
        instagram: "",
        brosur: "",
      },
      media: {
        video: "https://www.youtube.com/embed/1pqrKG76368",
        photo1: "2-1",
        photo2: "2-2",
        photo3: "2-3",
        photo4: "2-4",
      },
      about:
        "As a media team, planning to spend wisely and effectively has become our main strategy. We make sure people see our ads on their most relevant channel and most relevant time. Not only on TV, Radio, Billboard,or in print media, but also in digital placement such as Facebook, Youtube, Instagram, Google Ads, etc. As long as it is related to massiveness, we will see it as media investment for all Paragon’s brands.",
    },
    {
      id: 3,
      name: "Consumer Market Insight",
      logo: "",
      links: {
        website: "",
        whatsapp: "",
        instagram: "",
        brosur: "",
      },
      media: {
        video: "https://www.youtube.com/embed/tUx7GNQSvDU",
        photo1: "3-1",
        photo2: "3-2",
        photo3: "3-3",
        photo4: "3-4",
      },
      about:
        "Inspire, provoke and accelerate the business through consumer market insight to drive transformational business growth. We read between the lines & behind the numbers to deliver insights, foresight, ideas, and impact to drive innovation growth through various tools and research approaches that are achieved through a thorough understanding of what makes innovations work in-market and what helps consumers to live better quality of life.",
    },
    {
      id: 4,
      name: "Public Relation",
      logo: "4",
      links: {
        website: "",
        whatsapp: "",
        instagram: "",
        brosur: "",
      },
      media: {
        video: "https://www.youtube.com/embed/G9mekwo5jPs",
        photo1: "4-1",
        photo2: "4-2",
        photo3: "4-3",
        photo4: "4-4",
      },
      about:
        "We are lovely and cheerful people who love building strong and good relationships with various partners. We enjoy maintaining our bond with brand ambassadors, spokesperson, media, and other partners to raise the opportunities which leads to a positive image of the brand. We play an important role for brand exposure. We also spread good things about beauty, lifestyle, and community.",
    },
    {
      id: 5,
      name: "Digital Transformation",
      logo: "",
      links: {
        website: "",
        whatsapp: "",
        instagram: "",
        brosur: "",
      },
      media: {
        video: "https://www.youtube.com/embed/wjK4G0HJidY",
        photo1: "5-1",
        photo2: "5-2",
        photo3: "5-3",
        photo4: "5-4",
      },
      about:
        "We are a bunch of people that love to create buzz on social media, build homey digital houses for our brands (read: cool website), transform conventional user journeys into digital experiences, and utilize user data to retain them being our loyal consumers. Being the guardian of our digital assets and channels, we surely need to understand and engage with our audience continuously, and also keep adapting to the latest digital trend and technology. ",
    },
    {
      id: 6,
      name: "Production House, Design, & Creative",
      logo: "",
      links: {
        website: "",
        whatsapp: "",
        instagram: "",
        brosur: "",
      },
      media: {
        video: "https://www.youtube.com/embed/pI4mxHMAhpk",
        photo1: "6-1",
        photo2: "6-2",
        photo3: "6-3",
        photo4: "6-4",
      },
      about:
        "Creative is our middle name. Every day we are always dealing with ideas that are out of the box. To make Paragon’s brands more visible, we have to produce something different. Every audio, visual, and video is made wholeheartedly to attract the attention of consumers.",
    },
  ],
};

const getters = {
  gallery: (state) => {
    var toString = JSON.stringify(state.gallery);
    var objectGallery = JSON.parse(toString);
    return objectGallery;
  },
};

export default {
  state,
  getters,
};
