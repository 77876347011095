<template>
  <div>
    <img
      @click="showGallery"
      src="../../../assets/icons/interact.gif"
      alt="interact"
      class="icon-interact link-hotspot-icon"
    />
    <divisi-modal :name="name" :about="about">
      <template #imgcarousel>
        <!-- Video-->
        <b-carousel-slide v-if="video !== ''">
          <template #img>
            <iframe
              class="video-carousel"
              :src="video"
              allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </template>
        </b-carousel-slide>
        <b-carousel-slide>
          <template #img>
            <iframe
              class="video-carousel"
              :src="gallery.media.video2"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </template>
        </b-carousel-slide>
        <b-carousel-slide>
          <template #img>
            <iframe
              class="video-carousel"
              :src="gallery.media.video3"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </template>
        </b-carousel-slide>

        <b-carousel-slide>
          <template #img>
            <b-button class="cap-gall"
              ><a :href="image1Link" target="_blank">View detail</a></b-button
            >
            <img
              class="d-block flatimage"
              width="auto"
              height="398px"
              :src="image1"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>

        <b-carousel-slide>
          <template #img>
            <b-button class="cap-gall"
              ><a :href="image2Link" target="_blank">View detail</a></b-button
            >
            <img
              class="d-block flatimage-landscape"
              width="100%"
              height="auto"
              :src="image2"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>

        <b-carousel-slide>
          <template #img>
            <b-button class="cap-gall"
              ><a :href="image3Link" target="_blank">View detail</a></b-button
            >
            <img
              class="d-block flatimage-landscape"
              width="100%"
              height="auto"
              :src="image3"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>

        <b-carousel-slide>
          <template #img>
            <b-button class="cap-gall"
              ><a :href="image4Link" target="_blank">View detail</a></b-button
            >
            <img
              class="d-block flatimage-landscape"
              width="100%"
              height="auto"
              :src="image4"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>

        <b-carousel-slide>
          <template #img>
            <b-button class="cap-gall"
              ><a :href="image5Link" target="_blank">View detail</a></b-button
            >
            <img
              class="d-block flatimage-landscape"
              width="100%"
              height="auto"
              :src="image5"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>

        <b-carousel-slide>
          <template #img>
            <b-button class="cap-gall"
              ><a :href="image6Link" target="_blank">View detail</a></b-button
            >
            <img
              class="d-block flatimage-landscape"
              width="100%"
              height="auto"
              :src="image6"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>

        <b-carousel-slide>
          <template #img>
            <b-button class="cap-gall"
              ><a :href="image7Link" target="_blank">View detail</a></b-button
            >
            <img
              class="d-block flatimage-landscape"
              width="100%"
              height="auto"
              :src="image7"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>

        <b-carousel-slide>
          <template #img>
            <b-button class="cap-gall"
              ><a :href="image8Link" target="_blank">View detail</a></b-button
            >
            <img
              class="d-block flatimage"
              width="auto"
              height="398px"
              :src="image8"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
      </template>
      <template #buttons>
        <div class="btn-gall-wrapper">
          <p class="btn-head">Click below to learn more</p>
          <div class="d-flex gall-button">
            <a href="https://25tahun.wardahbeauty.com/" target="_blank">
              <img
                src="../../../assets/gallery/icon/wardah.png"
                alt="wardah"
                class="icon-gl"
              />
            </a>
            <a
              href="https://open.spotify.com/show/2ooYo7189HfPLbs3WAGEo2?si=zRshBiIUTjSQ540u149CJA"
              target="_blank"
              ><img
                src="../../../assets/gallery/icon/kahf.png"
                alt="kahf"
                class="icon-gl"
            /></a>
          </div>
        </div>
      </template>
    </divisi-modal>
  </div>
</template>


<script>
import DivisiModal from "../../modal/DivisiModal";
import { store } from "../../../store/index.js";
import { galleryMixin } from "../../../mixins/galleryMixin";

export default {
  // emits: ["pauseAudio"],
  components: {
    DivisiModal,
  },
  mixins: [galleryMixin],
  methods: {
    showGallery() {
      this.$bvModal.show("divisi");
      // this.$emit("pauseAudio");
      // var modaltoBody = document.getElementById("divisi___BV_modal_outer_")
      // var panodiv = document.getElementById("pano")
      // panodiv.appendChild(modaltoBody);
    },
  },
  computed: {
    gallery() {
      return store.getters.gallery[0];
    },
    image5() {
      if (this.gallery.media.photo5 !== "") {
        return "./assets/photos/" + this.gallery.media.photo5 + ".jpg";
      } else {
        return "";
      }
    },
    image6() {
      if (this.gallery.media.photo6 !== "") {
        return "./assets/photos/" + this.gallery.media.photo6 + ".jpg";
      } else {
        return "";
      }
    },
    image7() {
      if (this.gallery.media.photo7 !== "") {
        return "./assets/photos/" + this.gallery.media.photo7 + ".jpg";
      } else {
        return "";
      }
    },
    image8() {
      if (this.gallery.media.photo8 !== "") {
        return "./assets/photos/" + this.gallery.media.photo8 + ".jpg";
      } else {
        return "";
      }
    },
    image5Link() {
      return require("../../../../public/assets/photos/" +
        this.gallery.media.photo5 +
        ".jpg");
    },
    image6Link() {
      return require("../../../../public/assets/photos/" +
        this.gallery.media.photo6 +
        ".jpg");
    },
    image7Link() {
      return require("../../../../public/assets/photos/" +
        this.gallery.media.photo7 +
        ".jpg");
    },
    image8Link() {
      return require("../../../../public/assets/photos/" +
        this.gallery.media.photo8 +
        ".jpg");
    },
  },
};
</script>