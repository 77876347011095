<template>
  <div>
    <img
      v-b-modal.gallery
      src="../../../assets/icons/move.png"
      alt="interact"
      class="icon-interact link-hotspot-icon"
    />
    <gallery-modal></gallery-modal>
  </div>
</template>

<script>
import GalleryModal from "../../modal/GalleryModal";
export default {
  components: {
    GalleryModal,
  },
};
</script>