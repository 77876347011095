<template>
  <b-row class="zoom-schedule m-3">
    <b-col>
      <div class="text-left">
        <p class="d-inline starttime">{{ startTime }}</p>
        <p class="d-inline"> - </p>
        <p class="text-secondary d-inline">{{ endTime }}</p>
      </div>

      <div class="align-self-center">
        <p class="z-detail z-name font-weight-bold">
          {{ eventName }}
        </p>
        <p class="z-detail z-person text-secondary">{{ personName }}</p>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: [
    "startTime",
    "endTime",
    "eventName",
    "personName",
    "mode",
    "version01",
  ],
};
</script>

<style scoped>
.dot-sch{
  background-color: #c4c4c4;
  width: 10px;
  height: 10px;
  border-radius: 15px;
}
.zoom-schedule p {
  margin-bottom: 0;
  letter-spacing: 0.8px;
  cursor: default;
}
.starttime{
  color: #4d9897;
  font-weight: 500;
}
.z-detail {
  line-height: 22px;
  white-space: pre-wrap;
}
.z-name {
  letter-spacing: 0.8px;
  color: #4d9897;
}
.z-person {
  letter-spacing: 0.7px;
}
.vl {
  font-size: 10px;
  line-height: 4.75px;
}
</style>