import Vue from 'vue'
import Vuex from 'vuex'

import schedule from './modules/schedule'
import faq from './modules/faq'
import gallery from './modules/gallery'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: { schedule, faq, gallery }
});