const state = {
  scheduleSabtu: [
    {
      id: 1,
      startTime: "11.00",
      endTime: "12.00",
      eventName: "The Future of Marketing World",
      speaker:
        "Tessi Fathia (Senior Head of Digital Transformation)\n\nAlif Kartika (Group Head of Marketing Operation Excellence)\n\nModerator: Shabrina Salsabila",
    },
    {
      id: 2,
      startTime: "12.30",
      endTime: "13.30",
      eventName: "The Secret Recipe of Successful Brand Building",
      speaker:
        "Novia Sukmawaty (Group Head Wardah at Paragon Technology and Innovation),\n\nStephanie Lie (Group Head Make Over at Paragon Technology and Innovation),\n\nClarissa Gunawan (Group Head Emina at Paragon Technology and Innovation)\n\nModerator: Nuri Handayani",
    },
  ],

  scheduleMinggu: [
    {
      id: 1,
      startTime: "11.00",
      endTime: "12.00",
      eventName: "Reshaping Media Purpose on Beauty Industry",
      speaker:
        "Ressa Truna Priono (Head of Media & Strategic Partnership at Paragon Technology and Innovation)\n\nModerator: Rezy Afdhal",
    },
    {
      id: 2,
      startTime: "13.00",
      endTime: "14.00",
      eventName: "Consumer 101",
      speaker:
        "Putri Kirana (Group Head Consumer Market Insight)\n\nModerator: Karina Anindita",
    },
  ],
};

const getters = {
  scheduleSabtu: (state) => {
    return state.scheduleSabtu;
  },
  scheduleMinggu: (state) => {
    return state.scheduleMinggu;
  },
};

export default {
  state,
  getters,
};
