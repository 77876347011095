const state = {
  faq: [
    {
      id: 1,
      q: "Pertanyaan 1?",
      a: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, odit corporis eveniet molestias repudiandae mollitia alias error deleniti! Velit sunt molestiae ipsa, facere voluptates eos sapiente odit quos fuga qui."
    },
    {
      id: 2,
      q: "Pertanyaan 2?",
      a: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, odit corporis eveniet molestias repudiandae mollitia alias error deleniti! Velit sunt molestiae ipsa, facere voluptates eos sapiente odit quos fuga qui."
    },
    {
      id: 3,
      q: "Pertanyaan 3?",
      a: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, odit corporis eveniet molestias repudiandae mollitia alias error deleniti! Velit sunt molestiae ipsa, facere voluptates eos sapiente odit quos fuga qui."
    },
    {
      id: 4,
      q: "Pertanyaan 4?",
      a: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, odit corporis eveniet molestias repudiandae mollitia alias error deleniti! Velit sunt molestiae ipsa, facere voluptates eos sapiente odit quos fuga qui."
    },
    {
      id: 5,
      q: "Pertanyaan 5?",
      a: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, odit corporis eveniet molestias repudiandae mollitia alias error deleniti! Velit sunt molestiae ipsa, facere voluptates eos sapiente odit quos fuga qui."
    },
  ],
}

const getters = {
  faq: (state) => {
    return state.faq;
  },
};

export default {
  state,
  getters,
};
