<template>
  <div>
    <img
      v-b-modal.divisi
      src="../../../assets/icons/interact.gif"
      alt="interact"
      class="icon-interact link-hotspot-icon"
    />
    <divisi-modal :name="name" :about="about">
      <template #imgcarousel>
        <!-- Video-->
        <b-carousel-slide v-if="video !== ''">
          <template #img>
            <iframe
              class="video-carousel"
              :src="video"
              allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </template>
        </b-carousel-slide>

        <b-carousel-slide v-if="image1 !== ''">
          <template #img>
            <b-button class="cap-gall"
              ><a :href="image1Link" target="_blank">View detail</a></b-button
            >
            <img
              class="d-block flatimage-landscape"
              width="100%"
              height="auto"
              :src="image1"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>

        <b-carousel-slide v-if="image2 !== ''">
          <template #img>
            <b-button class="cap-gall"
              ><a :href="image2Link" target="_blank">View detail</a></b-button
            >
            <img
              class="d-block flatimage-landscape"
              width="100%"
              height="auto"
              :src="image2"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>

        <b-carousel-slide v-if="image3 !== ''">
          <template #img>
            <b-button class="cap-gall"
              ><a :href="image3Link" target="_blank">View detail</a></b-button
            >
            <img
              class="d-block flatimage-landscape"
              width="100%"
              height="auto"
              :src="image3"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>

        <b-carousel-slide v-if="image4 !== ''">
          <template #img>
            <b-button class="cap-gall"
              ><a :href="image4Link" target="_blank">View detail</a></b-button
            >
            <img
              class="d-block flatimage-landscape"
              width="100%"
              height="auto"
              :src="image4"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
      </template>
      <template #buttons>
        <div class="btn-gall-wrapper">
          <p class="btn-head">Click below to learn more</p>
          <div class="d-flex gall-button">
            <b-button
              class="btn-divisi"
              href="https://inspiringmovement.wardahbeauty.com/"
              target="_blank"
              >Inspiring Movement</b-button
            >
          </div>
        </div>
      </template>
    </divisi-modal>
  </div>
</template>


<script>
import DivisiModal from "../../modal/DivisiModal";
import { store } from "../../../store/index.js";
import { galleryMixin } from "../../../mixins/galleryMixin";

export default {
  components: {
    DivisiModal,
  },
  mixins: [galleryMixin],
  computed: {
    gallery() {
      return store.getters.gallery[3];
    },
  },
};
</script>