<template>
  <div>
    <img
      v-b-modal.stage
      src="../../../assets/icons/move.png"
      alt="interact"
      class="icon-interact link-hotspot-icon"
    />
    <stage-modal></stage-modal>
  </div>
</template>

<script>
import StageModal from "../../modal/StageModal";
export default {
  components: {
    StageModal,
  },
};
</script>