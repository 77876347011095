<template>
  <divisi-base :idDialog="'divisi'">
    <template>
      <b-row class="overflow-hidden">
        <b-col cols="12" md="7" class="pr-0">
          <b-carousel
            id="carousel-1"
            v-model="slide"
            controls
            indicators
            :interval="500000"
            background="lightgrey"
            style="text-shadow: 1px 1px 2px #333"
          >
            <slot name="imgcarousel"></slot>
          </b-carousel>
        </b-col>
        <b-col cols="12" md="5" class="divisi-textwrapper">
          <h4 class="pt-3 pb-2 divisi-title">{{ name }}</h4>
          <div>
            <p class="divisi-text">
              {{ about }}
            </p>
          </div>
          <slot name="buttons"></slot>
        </b-col>
      </b-row>
    </template>
  </divisi-base>
</template>

<script>
import DivisiBase from "../ui/DivisiBase.vue";

export default {
  components: {
    DivisiBase,
  },
  props: [
    "name",
    "about",
    "video1",
    "video2",
    "image1",
    "image2",
    "image3",
    "image4",
    "image1Link",
    "image2Link",
    "image3Link",
    "image4Link",
  ],
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>


<style>
.gall-button {
  /* position: absolute; */
  /* bottom: 4%; */
  width: 100%;
}
.btn-website {
  background-color: white;
  border: none;
  color: #adadad;
}
.btn-website:hover,
.btn-website:active,
.btn-website:focus,
.btn-website:active:focus {
  background-color: white !important;
  border: none !important;
  box-shadow: none !important;
  color: #adadad !important;
}
.carousel.slide {
  text-shadow: none !important;
  border-radius: 15px 0 0 15px !important;
}
.carousel-inner {
  border-radius: 15px 0 0 15px !important;
  height: 398px;
}
.carousel-item {
  height: 398px;
}
#divisi .carousel-indicators {
  width: 40%;
  margin: 0 auto 3%;
}
.carousel-indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border-top: none;
  border-bottom: none;
  opacity: 0.7;
  background-color: white;
}
.carousel-control-prev,
.carousel-control-next {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
}
.carousel-indicators .active {
  background-color: white;
}
.carousel-control-prev-icon {
  background-image: url("../../assets/icons/prevbutton.svg");
}
.carousel-control-next-icon {
  background-image: url("../../assets/icons/nextbutton.svg");
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 30px;
  height: 30px;
}
.modal-dialog-scrollable
  .modal-content.boothdialog__content#divisi___BV_modal_content_ {
  width: 60vw;
  min-height: 400px;
  max-height: 400px;
  border: 5px solid #4d9897;
}
.bodydialog-booth#divisi___BV_modal_body_ {
  padding: 0;
  /* background-color: #4d9897; */
  border-radius: 10px;
  overflow: hidden;
}
#divisi___BV_modal_header_ .close-boothdialog {
  right: -2%;
}
.divisi-textwrapper {
  padding: 10px 0 5px 25px;
  white-space: pre-wrap;
  background-color: white;
}
.divisi-title {
  letter-spacing: 1.1px;
  color: #4d9897;
  font-weight: 700;
  max-width: 85%;
  border-bottom: 2px solid #e0e0e0;
  font-size: 21px;
}
.divisi-text {
  height: 190px;
  letter-spacing: 0.7px;
  width: 90%;
  font-size: 14px;
  white-space: pre-wrap;
  text-align: justify;
  overflow-x: hidden;
  overflow-y: auto;
}
.btn-gall-wrapper {
  position: absolute;
  bottom: 4%;
  overflow: auto;
}
.btn-head {
  font-size: 14px;
  letter-spacing: 0.7px;
  margin-bottom: 8px;
}
.btn-divisi {
  background-color: #4d9897;
  height: 40px;
  line-height: 28px;
  border-radius: 15px;
  box-shadow: 0px 3px 6px #00000029;
  border: none;
  padding: 0.375rem 2rem !important;
  font-weight: 500;
  color: white !important;
  /* position: absolute; */
  bottom: 8%;
}
.btn-divisi-text {
  letter-spacing: 0.7px;
  margin: 0 auto;
  font-size: 14px;
}
.btn-divisi:hover,
.btn-divisi:focus,
.btn-divisi:active {
  background-color: #4d9897 !important;
  outline: none;
  box-shadow: 0px 3px 6px transparent !important;
}
.video-carousel {
  align-items: center;
  height: 398px;
  width: 100%;
}
.flatimage {
  margin: 0 auto;
}
.flatimage-landscape {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  max-height: 398px;
}
.cap-gall {
  position: absolute;
  z-index: 100;
  font-size: 14px;
  bottom: 1%;
  margin-bottom: 10px;
  right: 15px;
  color: black;
  text-decoration: none;
  border: none;
  background-color: white;
}
.cap-gall:hover,
.cap-gall:active,
.cap-gall:focus {
  background-color: white !important;
  outline: none;
  box-shadow: none;
}
.cap-gall a,
.cap-gall a:hover {
  color: black;
  text-decoration: none;
}
.icon-gl {
  cursor: pointer;
  width: 90%;
}
.link-hotspot-icon:focus {
  outline: none;
}

/* Scrollbar */
/* width */
/* #divisi.customscroll__career ::-webkit-scrollbar {
  width: 15px;
  background: transparent;
}

#divisi.customscroll__career ::-webkit-scrollbar-track {
  margin-top: 8px !important;
  margin-bottom: 15px;
  background: transparent;
  border-radius: 10px;
}
#divisi.customscroll__career ::-webkit-scrollbar-thumb {
  border-right: 0px solid rgba(0, 0, 0, 0);
  border-left: 13px solid rgba(0, 0, 0, 0);
  border-radius: 1px;
  background-clip: padding-box;
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
} */

@media only screen and (min-aspect-ratio: 9/20) and (orientation: portrait) {
  .modal-dialog-scrollable
    .modal-content.boothdialog__content#divisi___BV_modal_content_ {
    width: auto;
    max-width: 90vw;
    min-height: 83vh;
    max-height: 83vh;
  }
  #divisi___BV_modal_header_ .close-boothdialog {
    right: -8%;
    top: -3%;
  }
  #divisi .carousel-indicators {
    width: 55%;
  }
  .carousel.slide {
    border-radius: 15px 15px 0 0 !important;
    height: 30vh;
    width: 87vw;
  }
  .carousel-inner {
    border-radius: 15px 15px 0 0 !important;
    height: 30vh;
  }
  .carousel-item {
    height: 30vh;
  }
  .divisi-img {
    height: 30vh;
  }
  .divisi-textwrapper {
    height: 51vh;
    padding: 10px 0 25px 35px;
  }
  .divisi-title {
    font-size: 20px;
  }
  .divisi-text {
    height: 30vh;
  }
  .btn-divisi {
    margin-top: 10px;
    /* position: absolute; */
    bottom: 5%;
  }
  .flatimage {
    margin: 0 auto;
    height: 30vh;
    width: auto;
  }
  .flatimage-landscape {
    position: inherit;
    top: 15vh;
    transform: translateY(-50%);
    width: auto !important;
    max-width: 100%;
    max-height: 30vh;
    margin: 0 auto;
  }
  .cap-gall {
    bottom: 0;
  }
  .video-carousel {
    align-items: center;
    height: 30vh;
    width: 100%;
  }
}
@media only screen and (min-aspect-ratio: 9/16) and (orientation: portrait) {
  .divisi-text {
    width: 95%;
    height: 24vh;
  }
  #divisi___BV_modal_header_ .close-boothdialog {
    right: -8%;
    top: -3%;
  }
  .carousel-item,
  .carousel-inner {
    height: 30vh;
  }
}

/* Landscape Ipad*/
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1.5) {
  .modal-dialog-scrollable
    .modal-content.boothdialog__content#divisi___BV_modal_content_ {
    width: 70vw;
    min-height: 400px;
    max-height: 400px;
  }
  .cap-gall {
    bottom: 3%;
  }
}

/* Portrait Ipad */
@media only screen and (min-width: 768px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .col-md-7,
  .col-md-5 {
    flex: 100% !important;
    max-width: 100% !important;
  }
  .modal-dialog-scrollable
    .modal-content.boothdialog__content#divisi___BV_modal_content_ {
    min-height: 70vh;
    max-height: 70vh;
  }
  .carousel.slide {
    border-radius: 15px 15px 0 0 !important;
    height: 30vh;
  }
  .carousel-inner {
    border-radius: 15px 15px 0 0 !important;
    height: 30vh;
  }
  .divisi-textwrapper {
    max-height: 39vh;
  }
  .divisi-text {
    max-height: calc(28vh - 55px);
  }
  .divisi-title {
    font-size: 22px;
  }
  #divisi___BV_modal_header_ .close-boothdialog {
    top: -2%;
  }
  .cap-gall {
    bottom: -55%;
  }
}
</style>
