<template>
  <b-modal
    id="faq"
    hide-footer
    scrollable
    centered
    backdrop
    header-class="faqmodal__header"
    body-class="faqmodal__body"
    content-class="faqmodal__content"
    modal-class="customscroll"
    title="hehehe"
  >
    <template #modal-header="{ close }">
      <img
        src="../../assets/icons/close-circle.svg"
        class="close-boothdialog"
        alt="close"
        @click="close()"
      />
    </template>
    <div class="block">
      <div class="faq-title">FAQ</div>
      <div class="content-faq p-3">
        <div class="accordion" role="tablist">
          <b-card no-body class="mb-1 card-accordion">
            <b-card-header
              header-tag="header"
              class="p-1 cardheader-accordion"
              role="tab"
            >
              <b-button block v-b-toggle.accordion-1 variant="info"
                >Apa itu Paragon Marketing Space?</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-1"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="faq-cardbody">
                <b-card-text>
                  <p>
                    Paragon Marketing Space merupakan sebuah virtual space dari
                    Direktorat Marketing PT Paragon Technology and Innovation.
                    Disini, kamu bisa cari tahu secara detail tentang Marketing
                    di PT Paragon Technology and Innovation, baik dari lingkup
                    kerja, hingga output dari masing-masing divisinya. Jadi,
                    kamu bisa mengenal kita lebih dalam lagi.
                  </p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1 card-accordion">
            <b-card-header
              header-tag="header"
              class="p-1 cardheader-accordion"
              role="tab"
            >
              <b-button block v-b-toggle.accordion-2 variant="info"
                >Apa saja yang ada di Paragon Marketing Space?</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-2"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="faq-cardbody">
                <b-card-text>
                  <p>
                    1. Gallery <br />
                    Pada ruangan ini, kamu bisa melihat detail dan keseruan
                    acara dari masing-masing divisi di Marketing PT Paragon
                    Technology and Innovation dalam bentuk foto maupun video.
                    Kamu pun bisa coba beberapa fun activity yang ada di
                    masing-masing divisinya. <br /><br />
                    2. Main Stage <br />
                    Pada ruangan ini, kamu dapat mengikuti keseruan dari
                    Marketing Talk Show yang mendiskusikan marketing secara
                    mendalam dengan tema-tema yang menarik dan up-to-date.
                    Tentunya akan diisi oleh pembicara-pembicara inspiratif baik
                    dari internal maupun eksternal PT Paragon Technology and
                    Innovation.
                  </p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1 card-accordion">
            <b-card-header
              header-tag="header"
              class="p-1 cardheader-accordion"
              role="tab"
            >
              <b-button block v-b-toggle.accordion-3 variant="info"
                >Apa saja divisi yang ada di Marketing PT Paragon Technology and
                Innovation saat ini?</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-3"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="faq-cardbody">
                <b-card-text>
                  <p>
                    Saat ini Marketing PT Paragon Technology and Innovation
                    terdiri atas 6 divisi, Brand Development, Consumer Market
                    Insight, Digital Transformation, Media & Strategic
                    Partnership, Public Relation, dan Production
                    House-Design-Creative
                  </p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1 card-accordion">
            <b-card-header
              header-tag="header"
              class="p-1 cardheader-accordion"
              role="tab"
            >
              <b-button block v-b-toggle.accordion-4 variant="info"
                >Manfaat apa saja yg bisa didapatkan jika mengikuti seluruh
                rangkaian secara full?</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-4"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="faq-cardbody">
                <b-card-text>
                  <p>
                    Kamu bisa belajar lebih dalam tentang ilmu marketing,
                    menambah wawasan terkait beauty industry, dan juga bisa
                    mengetahui lebih jelas tentang opportunity career pathway di
                    Marketing PT. Paragon Technology and Innovation
                  </p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1 card-accordion">
            <b-card-header
              header-tag="header"
              class="p-1 cardheader-accordion"
              role="tab"
            >
              <b-button block v-b-toggle.accordion-5 variant="info"
                >Jika saya tidak bisa ikut rangkaian kegiatan Paragon Marketing
                Space secara lengkap, apakah masih bisa lihat
                rekamannya?</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-5"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="faq-cardbody">
                <b-card-text>
                  <p>
                    Kamu bisa lihat hasil rekaman acaranya di youtube channel
                    Marketing PT Paragon Technology and Innovation di
                    <a
                      class="linkfaq"
                      href="http://bit.ly/MarketingatParagonYoutube"
                      target="_blank"
                      >http://bit.ly/MarketingatParagonYoutube</a
                    >. Jangan lupa subscribe ya!
                  </p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1 card-accordion">
            <b-card-header
              header-tag="header"
              class="p-1 cardheader-accordion"
              role="tab"
            >
              <b-button block v-b-toggle.accordion-6 variant="info"
                >Apa bedanya Marketing PT Paragon Technology and Innovation
                dengan tempat lain?</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-6"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="faq-cardbody">
                <b-card-text>
                  <p>
                    Marketing di PT Paragon Technology and Innovation dihandle
                    sendiri oleh tim Paragon. Cause we are the headquarter, jadi
                    semua yang berhubungan dengan grand design, brand strategy
                    di-generated di Paragon. Jadi otomatis semua tim marketing
                    akan belajar banyak hal karena opportunity-nya sangat
                    terbuka luas dan juga tentunya bisa mengembangkan passion
                    yang dimiliki.
                  </p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1 card-accordion">
            <b-card-header
              header-tag="header"
              class="p-1 cardheader-accordion"
              role="tab"
            >
              <b-button block v-b-toggle.accordion-7 variant="info"
                >Inovasi paling breakthrough apa yang telah dicapai Marketing PT
                Paragon Technology and Innovation di sepanjang tahun
                ini?</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-7"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="faq-cardbody">
                <b-card-text>
                  <p>
                    Wardah telah berhasil menjadi brand nomor 1 pilihan wanita
                    Indonesia, Make Over telah berhasil meraih beberapa
                    penghargaan make up terbaik, produk lip color Emina menjadi
                    nomor 1 di Indonesia, dan Kahf menjadi produk Personal Care
                    pria nomor 1 di bulan pertama launching, dan banyak lagi.
                    Untuk tahu lebih dalam, kunjungi Gallery Room kami ya!
                  </p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1 card-accordion">
            <b-card-header
              header-tag="header"
              class="p-1 cardheader-accordion"
              role="tab"
            >
              <b-button block v-b-toggle.accordion-8 variant="info"
                >Kapan dan dimana bisa akses Paragon Marketing Space?</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-8"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="faq-cardbody">
                <b-card-text>
                  <p>
                    Kamu bisa akses kapan saja dan dimana saja melalui
                    <a
                      class="linkfaq"
                      href="https://marketing.paragon-innovation.com"
                      target="_blank"
                      >marketing.paragon-innovation.com</a
                    >
                  </p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1 card-accordion">
            <b-card-header
              header-tag="header"
              class="p-1 cardheader-accordion"
              role="tab"
            >
              <b-button block v-b-toggle.accordion-9 variant="info"
                >Bagaimana caranya untuk mengetahui update terkait
                kegiatan-kegiatan dari Marketing PT Paragon Technology and
                Innovation?</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-9"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="faq-cardbody">
                <b-card-text>
                  <p>
                    Kamu juga bisa kunjungi youtube channel Marketing PT Paragon
                    Technology and Innovation di
                    <a
                      class="linkfaq"
                      href="http://bit.ly/MarketingatParagonYoutube"
                      target="_blank"
                      >http://bit.ly/MarketingatParagonYoutube</a
                    >
                    Jangan lupa subscribe ya!
                  </p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1 card-accordion">
            <b-card-header
              header-tag="header"
              class="p-1 cardheader-accordion"
              role="tab"
            >
              <b-button block v-b-toggle.accordion-10 variant="info"
                >Apakah Marketing PT Paragon Technology and Innovation sedang
                membuka rekrutmen saat ini? Lowongan apa saja yang
                buka?</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-10"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="faq-cardbody">
                <b-card-text>
                  <p>
                    Untuk info lebih lanjut mengenai rekrutmen, kamu bisa
                    mengunjungi
                    <a
                      class="linkfaq"
                      href="https://career.paragon-innovation.com"
                      target="_blank"
                      >https://career.paragon-innovation.com</a
                    >
                    . Disana akan ada penjelasan lengkap mengenai lowongan apa
                    saja yang dibuka dan cara mendaftarnya. Good luck! Kami
                    tunggu kehadiranmu di Marketing Direktorat.
                  </p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>

          <b-card no-body class="mb-1 card-accordion">
            <b-card-header
              header-tag="header"
              class="p-1 cardheader-accordion"
              role="tab"
            >
              <b-button block v-b-toggle.accordion-11 variant="info"
                >Jika ada pertanyaan lebih lanjut, bisa kirim pertanyaan kemana ya?</b-button
              >
            </b-card-header>
            <b-collapse
              id="accordion-11"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="faq-cardbody">
                <b-card-text>
                  <p>
                    Selama Paragon Innovation Summit 2.0 berlangsung, kamu bisa
                    submit pertanyaan kamu di
                    <a
                      class="linkfaq"
                      href="https://pigeonhole.at/PIS20"
                      target="_blank"
                      >https://pigeonhole.at/PIS20</a
                    >. Jika Paragon Innovation Summit 2.0 sudah selesai, kamu
                    bisa kirim email ke marketing@pti-cosmmetics.com ya.
                  </p>
                </b-card-text>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ["idDialog", "title", "centertitle", "size", "boothcontent"],
  computed: {
    firstFaq() {
      var firstFaq = this.$store.getters.faq[0];
      console.log(firstFaq);
      return this.$store.getters.faq[0];
    },
    listFaq() {
      var faq = this.$store.getters.faq;
      var newFaq = [];
      for (let i = 1; i < faq.length; i++) {
        newFaq.push(faq[i]);
      }
      return newFaq;
    },
  },
};
</script>

<style>
.faq-title {
  color: white;
  font-size: 22px;
  letter-spacing: 1.2px;
  font-weight: bold;
  background-color: #67c8c7;
  padding: 10px 30px;
  border-bottom: 3px solid #e6e6e6;
  box-shadow: 0px 1px 10px #999;
  position: relative;
  z-index: 2;
}
.linkfaq {
  color: #365da5 !important;
}
.faq-cardbody {
  max-height: 250px;
  overflow-y: auto;
}
.faq-cardbody p {
  text-align: justify;
}
.faqmodal__header {
  padding: 0;
  border-bottom: 0;
}
.close-boothdialog {
  overflow: auto;
  margin-left: auto;
  position: absolute;
  top: -3%;
  right: -5%;
  z-index: 10000;
}
.close-boothdialog:hover {
  cursor: pointer;
}
.faqmodal__content {
  border: none;
  background-color: #e0e0e0;
}
.faqmodal__body {
  padding: 0;
  overflow: hidden !important;
  border-radius: 15px;
}
.modal-dialog-scrollable .modal-content.faqmodal__content {
  overflow: inherit;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  width: 50vh;
  max-height: 80vh;
}
.card-accordion {
  border: none;
  background-color: #e0e0e0;
}
.cardheader-accordion {
  background-color: transparent;
  border-bottom: 2px solid #67c8c7;
}
.content-faq {
  max-height: 65vh;
  overflow: auto;
}
.card-accordion .btn-info {
  background-color: #e0e0e0;
  color: black;
  border-color: transparent;
  text-align: left;
  font-weight: 500;
  letter-spacing: 1px;
}
.card-accordion .btn-info:active {
  background-color: transparent !important;
  color: black !important;
  border: none;
}
.card-accordion .btn-info:focus,
.card-accordion .btn-info:active:focus {
  box-shadow: none !important;
}

/* Scrollbar */
/* width */
.customscroll ::-webkit-scrollbar {
  width: 11px;
  background: transparent;
}
/* Track */
.customscroll ::-webkit-scrollbar-track {
  margin-top: 35px;
  margin-bottom: 15px;
  background: transparent;
  border-radius: 10px;
}
.customscroll ::-webkit-scrollbar-thumb {
  border-right: 4px solid rgba(0, 0, 0, 0);
  border-left: 4px solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  background-clip: padding-box;
  box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

/* Handle */
.customscroll ::-webkit-scrollbar-thumb {
  background-color: #67c8c7;
}

/* 20:9 */
@media only screen and (min-aspect-ratio: 9/20) and (orientation: portrait) {
  .modal-dialog-scrollable .modal-content.faqmodal__content {
    width: 94vmin;
  }
}

/* Portrait Ipad */
@media only screen and (min-width: 768px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .modal-dialog-scrollable .modal-content.faqmodal__content {
    width: 35vh;
    max-height: 50vh;
  }
}
</style>  